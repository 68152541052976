<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.766 36.765"><path d="M18.381 5.792a4.482 4.482 0 104.481 4.484 4.488 4.488 0 00-4.481-4.484zm0 7.611a3.129 3.129 0 113.128-3.127 3.133 3.133 0 01-3.128 3.127zM27.038 26.298a2.9 2.9 0 01-2.9 2.9h-1.771a.676.676 0 01-.675-.676v-5.7a.676.676 0 111.352 0v5.023h1.1a1.547 1.547 0 001.546-1.543v-7.616a1.546 1.546 0 00-1.546-1.542H12.619a1.539 1.539 0 00-1.533 1.542v7.616a1.54 1.54 0 001.533 1.543h1.1v-5.023a.677.677 0 011.354 0v5.7a.678.678 0 01-.673.677h-1.78a2.894 2.894 0 01-2.886-2.9v-7.616a2.893 2.893 0 012.886-2.894h11.525a2.9 2.9 0 012.9 2.894z"/><path fill="#fff" d="M27.038 26.298a2.9 2.9 0 01-2.9 2.9h-1.771a.676.676 0 01-.675-.676v-5.7a.676.676 0 111.352 0v5.023h1.1a1.547 1.547 0 001.546-1.543v-7.616a1.546 1.546 0 00-1.546-1.542H12.619a1.539 1.539 0 00-1.533 1.542v7.616a1.54 1.54 0 001.533 1.543h1.1v-5.023a.677.677 0 011.354 0v5.7a.678.678 0 01-.673.677h-1.78a2.894 2.894 0 01-2.886-2.9v-7.616a2.893 2.893 0 012.886-2.894h11.525a2.9 2.9 0 012.9 2.894z"/><path d="M27.038 18.682v7.616a2.9 2.9 0 01-2.9 2.9h-1.771a.676.676 0 01-.675-.676v-5.7a.676.676 0 111.352 0v5.023h1.1a1.547 1.547 0 001.546-1.543v-7.616a1.546 1.546 0 00-1.546-1.542H12.619a1.539 1.539 0 00-1.533 1.542v7.616a1.54 1.54 0 001.533 1.543h1.1v-5.023a.677.677 0 011.354 0v5.7a.678.678 0 01-.673.677h-1.78a2.894 2.894 0 01-2.886-2.9v-7.616a2.893 2.893 0 012.886-2.894h11.525a2.9 2.9 0 012.893 2.893z"/><path d="M18.383 0a18.382 18.382 0 1018.383 18.381A18.4 18.4 0 0018.383 0zm0 35.415a17.033 17.033 0 1117.034-17.034 17.053 17.053 0 01-17.034 17.034z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconSettingUsers'
  };
</script>
