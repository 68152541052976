<template>
  <section class="setting">
    <Banner>
      <div class="banner-content">
        <MeepIconSettings class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.setting") }}</span>
      </div>
    </Banner>
    <div class="setting-cards page-layout">
      <SettingCard
        v-if="!isJEPA"
        :content="$t('setting.companies-content')"
        :title="$t('setting.companies')"
        link="/dashboard/setting/companies"
      >
        <MeepIconSettingCompanies class="app-icon" />
      </SettingCard>
      <SettingCard
        v-if="isAdmin"
        :content="$t('setting.groupes-content')"
        :title="$t('setting.groupes')"
        link="/dashboard/setting/groupes"
      >
        <MeepIconSettingGroupes class="app-icon" />
      </SettingCard>
      <SettingCard
        :content="$t('setting.users-content')"
        :title="$t('setting.users')"
        link="/dashboard/setting/users"
      >
        <MeepIconSettingUsers class="app-icon" />
      </SettingCard>
      <SettingCard
        v-if="isAdmin && isJEPA"
        :content="$t('setting.cloud-content')"
        :title="$t('setting.cloud')"
        link="/dashboard/setting/fiscal-year"
      >
        <MeepIconCloud class="app-icon app-icon--fill-white" />
      </SettingCard>
    </div>
  </section>
</template>

<script>
import Banner from "@/components/Banner";
import SettingCard from "@/components/SettingCard";
import MeepIconSettings from "@/components/icons/MeepIconSettings.vue";
import MeepIconSettingCompanies from "@/components/icons/MeepIconSettingCompanies.vue";
import MeepIconSettingUsers from "@/components/icons/MeepIconSettingUsers.vue";
import MeepIconSettingGroupes from "@/components/icons/MeepIconSettingGroupes.vue";
import { mapGetters } from "vuex";
import MeepIconCloud from "@/components/icons/MeepIconCloud.vue";

export default {
  name: "Setting",
  components: {
    Banner,
    SettingCard,
    MeepIconSettings,
    MeepIconSettingCompanies,
    MeepIconSettingUsers,
    MeepIconSettingGroupes,
    MeepIconCloud,
  },
  computed: {
    ...mapGetters(["isClient", "isJEPA", "isAdmin", "isEmployee"]),
  },
  props: [],
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";
.setting {
  &-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
  }

  .setting-card {
    .setting-card-container .v-card .md-button {
      .md-ripple {
        font-size: toRem(14);
      }
    }
  }
  .app-icon--fill-white {
    path {
      fill: white;
    }
  }
}
</style>
