<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.766 36.765"><path d="M18.385 0a18.383 18.383 0 1018.383 18.381A18.4 18.4 0 0018.385 0zm0 35.416a17.033 17.033 0 1117.034-17.034 17.053 17.053 0 01-17.034 17.034z"/><path d="M14.436 16.381a.677.677 0 01-.674.675H5.948a.833.833 0 00-.834.829v5.164a.834.834 0 00.834.833h.618v-3.19a.675.675 0 011.35 0v3.865a.675.675 0 01-.674.674H5.949a2.185 2.185 0 01-2.184-2.181v-5.164a2.183 2.183 0 012.184-2.179h7.814a.676.676 0 01.673.674zM9.851 8.326a3.255 3.255 0 103.258 3.257 3.26 3.26 0 00-3.258-3.257zm0 5.16a1.9 1.9 0 111.908-1.9 1.906 1.906 0 01-1.908 1.9zM18.382 11.569a3.255 3.255 0 103.257 3.256 3.26 3.26 0 00-3.257-3.256zm0 5.16a1.905 1.905 0 111.907-1.9 1.907 1.907 0 01-1.907 1.9zM24.471 21.096v5.163a2.184 2.184 0 01-2.183 2.18h-1.2a.676.676 0 01-.675-.675v-3.866a.675.675 0 011.351 0v3.19h.525a.831.831 0 00.832-.829v-5.163a.831.831 0 00-.832-.829h-7.815a.827.827 0 00-.822.829v5.163a.826.826 0 00.822.829h.48v-3.19a.675.675 0 011.349 0v3.861a.674.674 0 01-.583.665v.005l-.047.005h-1.2a2.179 2.179 0 01-2.172-2.18v-5.163a2.179 2.179 0 012.172-2.18h7.815a2.184 2.184 0 012.183 2.185zM33.002 17.886v5.164a2.185 2.185 0 01-2.184 2.181h-1.293a.675.675 0 01-.675-.674v-3.865a.675.675 0 011.35 0v3.19h.619a.834.834 0 00.834-.833v-5.164a.833.833 0 00-.834-.829h-7.815a.675.675 0 010-1.349h7.815a2.183 2.183 0 012.183 2.179zM26.916 8.326a3.255 3.255 0 103.256 3.257 3.261 3.261 0 00-3.256-3.257zm0 5.16a1.9 1.9 0 111.906-1.9 1.907 1.907 0 01-1.906 1.9z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconSettingGroupes'
  };
</script>
